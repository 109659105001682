import React, { Component, useState, useEffect } from 'react';
import './assets/template/css/fonts.css';
import './assets/template/css/success.css';
export default class Loading extends Component {
	constructor(props) {
		super(props);
		
		let search = window.location.search;
		let params = new URLSearchParams(search);
	  }
	  render() {
	
		return (
		<div className="card" style={{marginTop:100}}>
				<h1>Loading Booking</h1> 
				<p>Please Wait</p>
			</div>
		);
	  }
}