import React, { Component, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/template/css/style.css';
import Loading from './Loading';


let arraySections = [];
let savedState = {};
let _selectedDateItem = null;
let _selectedSectionId = null;
let _selectedAttendeeAmount = null;
let _firstName = null;
let _lastName = null;
let _email = null;
let _phone = null;
let sectionConfig = null;
let websiteId;
const Home = ({ scene, location }) => {
	console.log(scene);


	var MyDate = new Date();

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let configuration = {};
	let availableReservationDates = [];
	let minSeats = 0;
	let maxSeats = 0;

	const [isSection2Visible, setSection2Visibility] = useState(false);

	const [dateSection, setDateSection] = useState(null);

	const [section1, setSection1] = useState(null);
	const [section2, setSection2] = useState(null);
	const [sectionAvailableSections, setSectionAvailableSections] = useState(null);
	const [sectionContactInfo, setSectionContactInfo] = useState(null);
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const [handling, setHandling] = useState(null);
	const [bannerStyle, setBannerStyle] = useState('');
	const [Title, setTitle] = useState('');
	const [Subtitle, setSubtitle] = useState('');

	const loadDateSection = (startAdvancedReservation, endAdvancedReservation) => {
		return <div className="form-group">
			<span className="form-label">Select a Date</span>
			<input className="form-control" onChange={e => onSelectDateChange(e)} type="date" required min={startAdvancedReservation} max={endAdvancedReservation} />
		</div>;
	}

	const loadSection1 = (list) => {
		return <div className="col-sm-12 col-sm-8">
			<div className="form-group">
				<span className="form-label">Max Attendees</span>
				<select className="form-control" onChange={k => OnSelectAttendee(k)}>
					{list}
				</select>
				<span className="select-arrow"></span>
			</div>
		</div>;
	}

	const loadSection2 = (array) => {
		return <div className="col-sm-12 col-sm-8">
			<div className="form-group">
				<span className="form-label">Select Time</span>
				<select className="form-control" onChange={e => OnSelectTime(e)}>
					{array}
				</select>
				<span className="select-arrow"></span>
			</div>
		</div>;
	}

	const loadSectionAvailableSections = (list) => {
		return <div className="col-sm-12 col-sm-8">
			<div className="form-group">
				<span className="form-label">Select a section</span>
				<select className="form-control" onChange={k => OnSelectSection(k)}>
					{list}
				</select>
				<span className="select-arrow"></span>
			</div>
		</div>;
	}

	const loadContactSection = () => {
		return <div className="row">
			<div className="col-sm-6">
				<div className="form-group">
					<span className="form-label">First Name*</span>
					<input className="form-control" onChange={(e) => onFirstNameChange(e)} type="text" required />
				</div>
			</div>
			<div className="col-sm-6">
				<div className="form-group">
					<span className="form-label">Last Name*</span>
					<input className="form-control" onChange={(e) => onLastNameChange(e)} type="text" required />
				</div>
			</div>
			<div className="col-sm-12">
				<div className="form-group">
					<span className="form-label">Email*</span>
					<input className="form-control" onChange={(e) => onEmailChange(e)} type="text" required />
				</div>
			</div>
			<div className="col-sm-12">
				<div className="form-group">
					<span className="form-label">Phone*</span>
					<input className="form-control" onChange={(e) => onPhoneChange(e)} type="text" required />
				</div>
			</div>
		</div>;
	}

	const onSelectDateChange = (e) => {
		const val = e.target.value;
		setSection1(null);
		setSection2(null);
		setSectionAvailableSections(null);
		fetch(`AvailableBookings?date=${val}&id=${websiteId}`)
			.then(response => response.json())
			.then(dates => {
				if (dates.length === 0) {
					alert('There are no reservation dates available for that day.');
					return;
				}

				dates.map((date) => {
					if (date.MinAvailableSeats < minSeats) {
						minSeats = date.MinAvailableSeats;
					}
					if (date.MaxAvailableSeats > maxSeats) {
						maxSeats = date.MaxAvailableSeats;
					}
				});

				availableReservationDates = dates;

				let array = [];
				let arrayText = [];
				arraySections = [];
				var index = 0;
				array.push(<option key={null}>Select a time</option>);
				dates.map((dateItem) => {
					dateItem.Times.map((time) => {
						var str = ConvertTimeToString(time);

						arraySections.push({
							time: str, dateItem
						});

						if (!array.includes(str)) {

							arrayText.push(str);
							array.push(<option key={index++} value={time}>{ConvertTimeToString(time)}</option>);
						}

					});
				});

				setSection2(loadSection2(array));
			});
	}

	const OnSelectTime = (e) => {
		setSectionAvailableSections(null);
		const val = ConvertTimeToString(e.target.value);
		var list = [];
		var listText = [];
		list.push(<option key={-1} value={null}>Select a section</option>);
		arraySections.map((section, index) => {
			if (!listText.includes(section.dateItem.AvailableBookingId)) {
				if (section.time == val) {
					listText.push(section.dateItem.AvailableBookingId);
					list.push(<option key={index} value={JSON.stringify(section.dateItem)}>{`${section.dateItem.Name}${section.dateItem.MaxAvailableSeats == 0 ? '' : ` (${section.dateItem.MaxAvailableSeats} attendees max)`}`}</option>);
				}
			}
		});

		setSectionAvailableSections(loadSectionAvailableSections(list));
	}

	const OnSelectSection = (e) => {
		if (e.target.value == null)
			return;

		var item = JSON.parse(e.target.value);
		_selectedDateItem = item;
		_selectedSectionId = item.AvailableBookingId;

		let list = [];
		list.push(<option key={null}>Select an attendee amount</option>);
		for (var i = item.MinAvailableSeats; i <= item.MaxAvailableSeats; i++)
			list.push(<option key={i}>{i}</option>);

		setSection1(loadSection1(list));
		//setSection1(loadSection1(list));
	}

	const OnSelectAttendee = (e) => {
		var foundavailableReservationDates = null;
		availableReservationDates.map((date) => {
			if (e.target.value >= date.MinAvailableSeats && e.target.value <= date.MaxAvailableSeats) {
				foundavailableReservationDates = date;
				//break;
			}
		});

		if (foundavailableReservationDates === null) {
			alert('We have no available sections for that size.');
			return;
		}

		_selectedAttendeeAmount = e.target.value;
	}

	const onSubmit = () => {
		setHandling(null);
		setButtonsDisabled(true);
		if (_firstName === '') {
			alert('First name is required');
			return;
		}
		else if (_lastName === '') {
			alert('Last name is required');
			return;
		}
		else if (_email === '') {
			alert('Email name is required');
			return;
		}
		else if (_selectedAttendeeAmount == null) {
			alert('Attendee amount was not selected');
			return;
		}
		else if (_selectedDateItem == null) {
			alert('Date and/or Time was not selected');
			return;
		}
		else if (_phone == '') {
			alert('Phone is required');
			return;
		}
		const obj = {
			SelectionSectionId: _selectedSectionId,
			SelectedDate: _selectedDateItem.Date,
			Email: _email,
			FirstName: _firstName,
			LastName: _lastName,
			Phone: _phone,
			Attendees: _selectedAttendeeAmount
		};
		fetch('Reservation/Book', {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(obj)
		})
			.then(response => response.json())
			.then(rc => {
				if (rc.Status == "success") {
					setHandling(<p className="handling" style={{ color: 'green' }}>{rc.Description}</p>);
				}
				else {
					setHandling(<p className="handling" style={{ color: 'red' }}>{rc.Description}</p>);
					setButtonsDisabled(false);
				}
			}).catch((error) => {
				setHandling(<p style={{ color: 'red' }} className="handling">There was an issue. Please refresh the page and try again.</p>);
				setButtonsDisabled(false);
			});
	}

	const onFirstNameChange = (e) => {
		const val = e.target.value;
		_firstName = val;
	}

	const onLastNameChange = (e) => {
		const val = e.target.value;
		_lastName = val;
	}

	const onEmailChange = (e) => {
		const val = e.target.value;
		_email = val;
	}

	const onPhoneChange = (e) => {
		const val = e.target.value;
		_phone = val;
	}

	const OnBack = () => {

		setSection2Visibility(false);

	}
	const OnNext = () => {

		setSection2Visibility(true);

	}
	const ConvertTimeToString = (time) => {
		const date = new Date(time);
		return `${date.getHours() > 12 ? `${(date.getHours() - 12)}` : `${date.getHours() === 0 ? '12' : date.getHours()}`}:${date.getMinutes() === 0 ? `0${date.getMinutes()}` : `${date.getMinutes()}`} ${date.getHours() > 12 ? 'PM' : 'AM'}`;
	}
	useEffect(() => {
		websiteId = params.get('id');
		document.title = "IndieAbbey";
		if (websiteId === null) {
			window.location.href = 'https://indieabbey.com/';
		}

		//OnSelectTime();
		//return;
		fetch('Configuration?id=' + websiteId)
			.then(response => response.json())
			.then(config => {
				console.log(config);
				configuration = config;
				const startAdvancedReservation = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + MyDate.getDate()).slice(-2)}`;
				const endAdvancedReservation = `${MyDate.getFullYear()}-${('0' + (MyDate.getMonth() + 1)).slice(-2)}-${('0' + (MyDate.getDate() + config.InAdvanceReservationTime)).slice(-2)}`;
				setDateSection(loadDateSection(startAdvancedReservation, endAdvancedReservation));
			});

		fetch('BookingManagement?id=' + websiteId)
			.then(response => response.json())
			.then(rc => {
				if (rc.Status == "success") {
					sectionConfig = rc.Model;
					document.title = `${rc.Model.Website.DomainName}`;

					setBannerStyle(rc.Model.AdditionalText2);
					setTitle(rc.Model.Content);
					setSubtitle(rc.Model.AdditionalText1);
				}
				else {

				}
			});

		setSectionContactInfo(loadContactSection());
	}, [])
	return (
		<>
			<div style={{display:dateSection == null ? 'block' : 'none'}}>
				<Loading></Loading>
			</div>

			<div id="booking" className="section" style={{ backgroundImage: `url(${bannerStyle})`, display:dateSection != null ? 'block' : 'none' }}>
				<div className="section-center">
					<div className="container">
						<div className="row">
							<div className="col-md-7 col-md-push-5">
								<div className="booking-cta">
									<h1>{Title}</h1>
									<p>
										{Subtitle}
									</p>
								</div>
							</div>
							<div className="col-md-4 col-md-pull-7">
								<div className="booking-form">
									<div className="row">
										<div style={{ display: isSection2Visible === true ? 'none' : 'block' }} className="col-sm-12">
											{dateSection}
										</div>
									</div>
									<div className="row" style={{ display: isSection2Visible === true ? 'none' : 'block' }}>
										{section2}
										{sectionAvailableSections}
										{section1}
										<button onClick={() => OnNext()} className="submit-btn" style={{ width: '100%', display: section2 == null ? 'none' : 'block' }}>Next</button>
									</div>
									<div style={{ display: isSection2Visible === false ? 'none' : 'block' }}>
										{sectionContactInfo}
										{handling}
										<button onClick={() => onSubmit()} className="submit-btn" style={{ width: '100%', marginBottom: 20, display: buttonsDisabled ? 'none' : 'block' }}>Set Reservation</button>
										<button onClick={() => OnBack()} className="submit-btn" style={{ backgroundColor: 'red', color: 'white', width: '100%', display: buttonsDisabled ? 'none' : 'block' }}>Back</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default Home;
