import React, { Component, useState, useEffect } from 'react';
import './assets/template/css/fonts.css';
import './assets/template/css/success.css';
export default class Reservation extends Component {
	constructor(props) {
		super(props);
		document.title = "Reservation Status";

		let search = window.location.search;
		let params = new URLSearchParams(search);
		const id = params.get('id');
		this.state = {
			handlingTitle:'Loading Reservation',
			handlingDescription:''
		}

		if (id !== null) {
			this.onCancel(id);
		}
		else
		{
			this.setState({
				handlingTitle:'Uh oh',
				handlingDescription:"No reservation id provided"
			});
		}
	  }

	  onCancel = (id) => {
		fetch('Cancel?id=' + id)
		.then(response => response.json())
		.then(rc => {
			this.setState({
				handlingTitle:'Reservation Status',
				handlingDescription:rc.Description
			})
		}).catch((error) => {
			this.setState({
				handlingTitle:'Uh oh',
				handlingDescription:"There was an issue. Please try again."
			})
		});;
	  }
	  render() {
	
		return (
		<div className="card"  style={{marginTop:100}}>
				<h1>{this.state.handlingTitle}</h1> 
				<p>{this.state.handlingDescription}</p>
			</div>
		);
	  }
}